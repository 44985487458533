import {Pipe, PipeTransform} from "@angular/core";
import Autolinker, {HashtagMatch} from "autolinker";
import {Activity} from "../activity.model";
import {Url} from "../activity.interface";
import {ProfileTypes} from "@shared/channel/profile-types.enum";

@Pipe({
  name: "linkify",
})
export class LinkifyPipe implements PipeTransform {
  transform(text: string, activity: Activity): string {
    if (text) {
      // Remove the caret from quill, the ASCII code is 65279 (BOM)
      text = text.replace("﻿", "");
      text = this.linkifyPublishMessageMentions(text, activity);
      text = this.removeUrlIfHasPhoto(text, activity);
      text = this.removeUrlIfHasQuotedTweet(text, activity);
      text = this.linkify(text, activity);
      text = this.reverseTwitterLinks(text, activity);
    }
    return text;
  }

  linkifyPublishMessageMentions(text: string, activity: Activity): string {
    if (!activity.Mentions) return text;

    const validSourceTypes = ["Facebook", "LinkedIn"];
    if (!validSourceTypes.some((x) => x == activity.SourceType)) return text;

    // Sort in descending order so we can safely splice from the end
    activity.Mentions = activity.Mentions.sort((a, b) => b.IndexStart - a.IndexStart);

    for (const activityMention of activity.Mentions) {
      const mentionAnchor = this.getHtmlAnchor(
        this.getHrefDependingOnSourceType(activity.SourceType, activityMention.Reference),
        activityMention.Name,
      );

      const countOfLettersToRemove = activityMention.IndexEnd - activityMention.IndexStart + 1;
      text = this.splice(text, activityMention.IndexStart, countOfLettersToRemove, mentionAnchor);
    }

    return text;
  }

  private linkify(text: string, activity: Activity): string {
    // Generate a unique placeholder using a random string
    const uniqueId = Math.random().toString(36).substring(2, 15);
    const placeholderPattern = `__MENTION_${uniqueId}__`;

    // Temporarily replace existing anchor tags
    const anchorRegex = /<a[^>]*>.*?<\/a>/g;
    const anchors: string[] = [];
    text = text.replace(anchorRegex, (match) => {
      anchors.push(match);
      return `${placeholderPattern}${anchors.length - 1}`;
    });

    // Escape all remaining < and > in user text
    text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");

    // Restore the anchors
    text = text.replace(new RegExp(`${placeholderPattern}(\\d+)`, "g"), (_, idx) => anchors[+idx]);

    const mention = activity.Tweet ? "twitter" : activity.InstagramMedia ? "instagram" : false;

    // Replace newlines with <br>
    const regexForAllLineBreaks = new RegExp("\n\r|\n", "g");
    text = text.replace(regexForAllLineBreaks, "</br>");

    const currentNetwork = activity.SourceType;

    text = Autolinker.link(text, {
      stripPrefix: false,
      stripTrailingSlash: false,
      hashtag: "twitter",
      mention,
      replaceFn: (match) => {
        const originalTag = match.buildTag();

        if (match.getType() == "hashtag") {
          const entireMatchText = match.getMatchedText();
          if (!/^#[A-Za-z0-9_]+$/.test(entireMatchText)) {
            return false; // skip invalid hashtags
          }
          const hashtagMatch = match as HashtagMatch;
          return new Autolinker.HtmlTag({
            tagName: "a",
            attrs: {
              href: this.getHrefForHashtagDependingOnSourceType(currentNetwork, hashtagMatch.getHashtag()),
              target: "_blank",
              class: "shared-activityhashtag-a",
            },
            innerHtml: originalTag.getInnerHTML(),
          });
        }

        if (match.getType() == "url") {
          return new Autolinker.HtmlTag({
            tagName: originalTag.getTagName(),
            attrs: {
              href: originalTag.getAttr("href"),
              target: "_blank",
              class: "shared-activityurl-a",
            },
            innerHtml: originalTag.getInnerHTML(),
          });
        }

        // For mentions or other match types, create a normal link
        return new Autolinker.HtmlTag({
          tagName: originalTag.getTagName(),
          attrs: {
            href: originalTag.getAttr("href"),
            target: "_blank",
          },
          innerHtml: originalTag.getInnerHTML(),
        });
      },
    });

    return text;
  }

  private reverseTwitterLinks(text: string, activity: Activity): string {
    if (activity.Tweet && activity.Tweet.Urls && activity.Tweet.Urls.length) {
      activity.Tweet.Urls.forEach((url: Url) => {
        text = text.replace(
          this.getHtmlAnchor(url.BareUrl, url.BareUrl),
          this.getHtmlAnchor(url.BareUrl, url.DisplayUrl),
        );
      });
    }
    return text;
  }

  private removeUrlIfHasPhoto(text: string, activity: Activity): string {
    if (activity.Tweet && activity.Tweet.Photos && activity.Tweet.Photos.length) {
      activity.Tweet.Photos.forEach((photo) => {
        text = text.replace(photo.Url, "");
      });
    }
    return text;
  }

  private removeUrlIfHasQuotedTweet(text: string, activity: Activity): string {
    if (activity.Tweet && activity.Tweet.QuotedStatusId && (!activity.Tweet.Photos || !activity.Tweet.Photos.length)) {
      activity.Tweet.Urls.forEach((url) => {
        text = text.replace(url.BareUrl, "");
      });
    }
    return text;
  }

  private getHtmlAnchor(href: string, label: string): string {
    return '<a href="' + href + '" target="_blank" data-test="shared-activitymention-a">' + label + "</a>";
  }

  private getHrefDependingOnSourceType(sourceType: ProfileTypes | string, externalId: string) {
    if (!externalId) return "";

    if (sourceType == ProfileTypes.Facebook || sourceType == "Facebook") {
      return "https://www.facebook.com/" + externalId;
    } else if (sourceType == ProfileTypes.LinkedIn || sourceType == "LinkedIn") {
      if (externalId.startsWith("urn:li:organization:")) {
        externalId = externalId.replace("urn:li:organization:", "");
        return "https://linkedin.com/company/" + externalId;
      }
      return "https://www.linkedin.com/in/" + externalId;
    }
  }

  private splice(text, idx, rem, str) {
    return text.slice(0, idx) + str + text.slice(idx + Math.abs(rem));
  }

  private getHrefForHashtagDependingOnSourceType(sourceType: ProfileTypes | string, hashtag: string) {
    if (sourceType == ProfileTypes.Facebook || sourceType == "Facebook") {
      return "https://www.facebook.com/hashtag/" + hashtag;
    } else if (sourceType == ProfileTypes.TwitterAccount || sourceType == "TwitterAccount") {
      return "https://twitter.com/hashtag/" + hashtag;
    } else if (sourceType == ProfileTypes.InstagramAccount || sourceType == "InstagramAccount") {
      return "https://instagram.com/explore/tags/" + hashtag;
    } else if (sourceType == ProfileTypes.LinkedIn || sourceType == "LinkedIn") {
      return "https://www.linkedin.com/feed/hashtag/?keywords=" + hashtag.toLowerCase();
    }

    return "#";
  }
}
